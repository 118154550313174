import React, { Component } from 'react';
import 'react-dates/initialize';
import PropTypes from 'prop-types';
import Select from 'react-select';

import * as dayjs from 'dayjs';
import moment from 'moment';
import isBetween from 'dayjs/plugin/isBetween';

import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';

import loader from '../images/button-loader.svg';
import pageloader from '../images/page-loader.svg';

import ScrollTop from '../components/ScrollTop';

import { ThemeContext } from '../context/ThemeProvider';
import listingServices from '../services/ListingService';
import { toDanishCurrency, setMonthDayToEndofMonth } from '../utilities/helpers';

import 'react-dates/lib/css/_datepicker.css';
import '../styles/searchbar.scss';
import Axios from 'axios';
// import ButtonDropdown from './buttonDropdown';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();
dayjs.extend(isBetween);

function noop() {}

export class Searchbar extends Component {
    constructor(props) {
        super(props);

        let defaultCheckin = props.checkin || '';
        let defaultCheckout = props.checkout || '';
        let defaultGuest = props.guests || props.defaultGuest || 0;
        let defaultDates = null;
        let defaultMaxNumberOfGuests = props.maxGuest || 8;
        let guestOptions = [];
        let selectedGuest = null;
        let defaultIsLoadingPrice = (props.checkin && props.checkout && props.pageType === 'Listing');
        
        for (let index = 0; index < defaultMaxNumberOfGuests; index++) {            
            if (defaultGuest === index + 1) {
                selectedGuest = {value: index + 1, label: `${index + 1} guest${index + 1 > 1 ? 's' : ''}`};
            }
            guestOptions.push({value: index + 1, label: `${index + 1} guest${index + 1 > 1 ? 's' : ''}`});
        }
        
        let firstDate = dayjs().startOf('month');
        let secondDate = dayjs().add(1, 'month').endOf('month');
        
        let defaultStartDate = null, defaultEndDate = null;

        try {
            defaultStartDate = dayjs(defaultCheckin);
            if (!defaultStartDate.isValid()) {
                defaultStartDate = null;
            }
        }
        catch {

        }

        try {
            defaultEndDate = dayjs(defaultCheckout);
            if (!defaultEndDate.isValid()) {
                defaultEndDate = null;
            }
        }
        catch {

        }

        this.state = {
            data: {
                checkin: defaultCheckin,
                checkout: defaultCheckout, 
                numberOfGuests: defaultGuest,
                listingInfo: {
                avgPerNight: 0,
                    totalPrice: 0,
                    totalNights: 0,
                    status: ''
                }
            },
            selectedGuests: selectedGuest,
            isLoadingPrice: defaultIsLoadingPrice,
            maxNumberOfGuests: defaultMaxNumberOfGuests,
            isSearch: true,
            dates: defaultDates,
            showCalendar: false,
            guestOptions: guestOptions,
            screenSize: {
                width: 0,
                height: 0
            },
            monthShown: {
                first: firstDate,
                second: secondDate,
                tempStartDate: dayjs().startOf('month'),
                tempEndDate: dayjs().add(1, 'month').endOf('month'),
                availabilityRequestThreshold: 6, // if user reaches 'x' no of next, will trigger request to backend
                monthCount: 0,
                availabilityRequestCount: 12, // no of months to include in the request
                tempRawDateSched: [],
                tempInvalidDates: []
            },
            toggleSearchbar: false,
            initialDatesAvailabilityLoaded: false,
            rawDateSched: [],
            invalidDates: [],
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            focusedInput: 'startDate',
            minimumNights: 1,
            calendarOrientation: 'horizontal',
            isListed: false
        }

        this.onResize = this.onResize.bind(this);
        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
        this.onPreviousMonthClick = this.onPreviousMonthClick.bind(this);
        this.onNextMonthClick = this.onNextMonthClick.bind(this);
        this.onInquire = this.onInquire.bind(this);
        this.handleClearDates = this.handleClearDates.bind(this);

        this.toggleButtonRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.onOrientationChange);
        this.onResize();

        const { listingId } = this.props;
        if (listingId) {
            let { monthShown } = this.state;
            let checkoutDefault = new Date();
            checkoutDefault.setMonth(checkoutDefault.getMonth() + monthShown.availabilityRequestCount);
            checkoutDefault = setMonthDayToEndofMonth(checkoutDefault);
            await this.getDatesStatus(monthShown.first, checkoutDefault).then(async () => {
                this.setState({ initialDatesAvailabilityLoaded: true });    
                await this.getListingPrice();
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.onOrientationChange);
        source.cancel();
    }

    onResize() {
        let { calendarOrientation ,toggleSearchbar } = this.state;
        const screenSize = {
            width: document.body.clientWidth,
            height: document.body.clientHeight
        };

        if (screenSize.width <= 576) {
            calendarOrientation = 'vertical';
        }

        this.setState({ screenSize, calendarOrientation }, () => {
            this.computeFooterMargin();
        });

        if(!toggleSearchbar) {
            var isLargeDisplay = window.matchMedia("(min-width: 768px)").matches;
            if(!isLargeDisplay) {
                const element = this.toggleButtonRef.current;
                if(element) {   
                    //trigger click button
                    element.click();
                }
            }
        }
    }

    onOrientationChange = () => {
        this.onResize();
    }

    computeFooterMargin() {
        const { toggleSearchbar, screenSize } = this.state;
        
        if (screenSize.width <= 760) {
            let footer = document.getElementsByTagName('footer')[0];
            let scrollTop = document.getElementById("scrollTop");

            if (toggleSearchbar) {
                let hiddenSearchbar = document.getElementsByClassName('hide-searchbar');
                if (hiddenSearchbar.length > 0) {

                    footer.style.marginBottom = '0px';
                    
                    scrollTop.style.bottom = "40px";
                    scrollTop.style.transform = "translateY(0px)";
                }
                else {
                    let searchBar = document.getElementsByClassName('searchbar')[0];
                    footer.style.marginBottom = searchBar.getBoundingClientRect().height + 'px';
                    scrollTop.style.bottom = "40px";
                    scrollTop.style.transform = "translateY(-"+searchBar.getBoundingClientRect().height+"px)";
                }
            }
            else {
                let searchBar = document.getElementsByClassName('searchbar')[0];        
                footer.style.marginBottom = searchBar.getBoundingClientRect().height + 'px';
                
                // console.log(window.innerHeight, document.getElementsByClassName('searchbar')[0].getBoundingClientRect().y)
                scrollTop.style.bottom = "40px";
                scrollTop.style.transform = "translateY(-"+searchBar.getBoundingClientRect().height+"px)";
            }
        }
        else {
            let footer = document.getElementsByTagName('footer')[0];    
            let searchBar = document.getElementsByClassName('searchbar')[0];
            footer.style.marginBottom = searchBar.getBoundingClientRect().height + 'px';

            if (!toggleSearchbar) {
                searchBar.style.transform = "translateY(0)";

                let scrollTop = document.getElementById("scrollTop");
                scrollTop.style.transform = "translateY(0)";

                if(screenSize.width <= 900) {
                    scrollTop.style.bottom = "310px";
                    
                } else {
                    scrollTop.style.bottom = "110px";
                }
            }
        }
    }

    async getDatesStatus(checkin, checkout) {
        const { listingId } = this.props;
        checkout = setMonthDayToEndofMonth(checkout);

        source.cancel();
        source = CancelToken.source();

        await listingServices.checkListingAvailability({ ListingId: listingId }, source.token).then(async res => {
            if (res.data) {
                await listingServices.getListingAvailabilityByDate(listingId, checkin, checkout, 0, source.token).then(response => {
                    if (response.success) {
                        if (response.data) {
                            let { monthShown, rawDateSched, invalidDates } = this.state;
                            monthShown.monthCount = 0;
                            invalidDates = invalidDates.concat(response.data.filter(data => data.isValid === false));
                            rawDateSched = rawDateSched.concat(response.data);
        
                            if (monthShown.tempInvalidDates.length <= 0 && monthShown.tempRawDateSched.length <= 0) {
                                monthShown.tempStartDate = monthShown.tempStartDate.subtract(2, 'M').startOf('month');
                                monthShown.tempEndDate = monthShown.tempEndDate.add(2, 'M').endOf('month');
        
                                monthShown.tempInvalidDates = invalidDates.filter(x => dayjs(x.date).isBetween(monthShown.tempStartDate, monthShown.tempEndDate));
                                monthShown.tempRawDateSched = rawDateSched.filter(x => dayjs(x.date).isBetween(monthShown.first.startOf('month'), monthShown.second.endOf('month')));
                            }
        
                            this.setState({ monthShown, rawDateSched, invalidDates, isListed: res.data });
                        }
                    }
                });
            }
            else {
                this.setState({ isListed: res.data });
            }
        })
    }

    onPreviousMonthClick(e) {
        const { listingId } = this.props;
        const { isListed } = this.state;

        if (listingId && isListed) {
            let { monthShown, invalidDates, rawDateSched } = this.state;
            monthShown.tempStartDate = dayjs(monthShown.tempStartDate).subtract(1, 'M').startOf('month');
            monthShown.tempEndDate = dayjs(monthShown.tempEndDate).subtract(1, 'M').endOf('month');

            monthShown.first = monthShown.first.subtract(1, 'month');
            monthShown.second = monthShown.second.subtract(1, 'month');
            monthShown.monthCount = monthShown.monthCount - 1;

            monthShown.tempInvalidDates = invalidDates.filter(x => dayjs(x.date).isBetween(monthShown.tempStartDate, monthShown.tempEndDate) && !x.isValid);
            monthShown.tempRawDateSched = rawDateSched.filter(x => dayjs(x.date).isBetween(monthShown.first.startOf('month'), monthShown.second.endOf('month')) && x.isValid);

            this.setState({ monthShown });
        }
    }

    onNextMonthClick(e) {
        const { listingId } = this.props;
        const { isListed } = this.state;

        if (listingId && isListed) {
            let { monthShown, invalidDates, rawDateSched } = this.state;
            monthShown.tempStartDate = dayjs(monthShown.tempStartDate).add(1, 'M').startOf('month');
            monthShown.tempEndDate = dayjs(monthShown.tempEndDate).add(1, 'M').endOf('month');

            monthShown.first = monthShown.first.add(1, 'month');
            monthShown.second = monthShown.second.add(1, 'month');
            monthShown.monthCount = monthShown.monthCount + 1;

            monthShown.tempInvalidDates = invalidDates.filter(x => dayjs(x.date).isBetween(monthShown.tempStartDate, monthShown.tempEndDate) && !x.isValid);
            monthShown.tempRawDateSched = rawDateSched.filter(x => dayjs(x.date).isBetween(monthShown.first.startOf('month'), monthShown.second.endOf('month')) && x.isValid);

            this.setState({ monthShown }, async () => {
                if (monthShown.monthCount === monthShown.availabilityRequestThreshold) {
                    let checkoutDefault = new Date(monthShown.second);
                    checkoutDefault.setMonth(checkoutDefault.getMonth() + monthShown.availabilityRequestCount);
                    checkoutDefault = setMonthDayToEndofMonth(checkoutDefault);
                    await this.getDatesStatus(monthShown.second, checkoutDefault);
                }
            });
        }
    }

    async getListingPrice() {
        const { listingId, pageType } = this.props;

        source.cancel();
        source = CancelToken.source();
        let { data, startDate, endDate, showCalendar } = this.state;

        if (listingId && data.checkin && data.checkout && !showCalendar) {
            let isLoadingPrice = true;
    
            if (pageType === 'Listings' || pageType === 'Page' ) {
                isLoadingPrice = false;
            }
    
            this.setState({ isLoadingPrice });
            
            listingServices.getListingAvailability(listingId, data.checkin, data.checkout, data.numberOfGuests, source.token).then(response => {
                if (response.success) {
                    if (response.data) {
                        if(response.data.status === 'available') {
                            data.listingInfo.avgPerNight = response.data.avgPerNight;
                            data.listingInfo.totalPrice = response.data.totalPrice;
                            data.listingInfo.totalNights = response.data.totalNights;
                            data.listingInfo.status = response.data.status;

                            if (document.body.clientWidth <= 576) {
                                document.getElementsByClassName('searchbar')[0].style.height = '369px';
                            }
                        }
                        else {
                            data = {
                                checkin: '',
                                checkout: '', 
                                numberOfGuests: 0,
                                listingInfo: {
                                    avgPerNight: 0,
                                    totalPrice: 0,
                                    totalNights: 0,
                                    status: ''
                                }
                            }
                            
                            alert('The selected dates are currently unavailable.');
                        }

                        this.setState({ data, startDate, endDate, isLoadingPrice: false });
                    }
                    
                    this.computeFooterMargin();
                }
                else {
                    this.setState({ isLoadingPrice: false });
                }
            });
        }
    }

    onButtonClick = async (e) => {
        const { checkin, checkout, numberOfGuests, listingInfo } = this.state.data;

        if (checkin && checkout) {
            let tempCheckIn = new Date(checkin);
            let tempCheckOut = new Date(checkout);
            let isValid = dayjs(tempCheckIn).isBefore(dayjs(tempCheckOut), 'day');
            if (!isValid) {
                alert("Your selected dates are invalid.");
                return;
            }
        }

        if (this.props.onSearchClick) {
            this.props.onSearchClick({
                checkin: checkin,
                checkout: checkout,
                guests: numberOfGuests,
                listingInfo
            });
        }
        else {
            await this.getListingPrice();
        }
    }

    onInquire = (e) => {
        const { checkin, checkout, numberOfGuests, listingInfo } = this.state.data;
        if (this.props.onInquiryClick !== undefined) {
            this.props.onInquiryClick({
                checkin: checkin,
                checkout: checkout,
                guests: numberOfGuests,
                listingInfo
            });
        }
    }

    onGuestCountChange = async (selectedGuests) => {
        const { pageType } = this.props;
        const { data } = this.state;
        let isLoadingPrice = false;
        data.numberOfGuests = selectedGuests.value;

        if (data.checkin && data.checkout) {
            isLoadingPrice = true;
        }

        if (pageType === 'Listings' || pageType === 'Page' ) {
            isLoadingPrice = false;
        }

        this.setState({ selectedGuests, data, isLoadingPrice }, async () => {
            if (data.checkin && data.checkout) {
                await this.getListingPrice();
            }
        })
    }

    onSearchbarToggle = (e) => {
        const { toggleSearchbar } = this.state;
        let searchbar = document.getElementsByClassName("searchbar")[0];
        let toggleElement = document.getElementsByClassName('searchbar-toggle')[0];

        if (toggleSearchbar) {
            searchbar.style.transform = '';
            document.getElementById("scrollTop").style.transform = "translateY(0px)";
            toggleElement.classList.remove("hide-searchbar");
            toggleElement.innerHTML = "Hide Search";
        }
        else {
            searchbar.style.transform = `translateY(${searchbar.clientHeight}px)`;
            document.getElementById("scrollTop").style.transform = `translateY(${searchbar.clientHeight}px)`;
            toggleElement.classList.add("hide-searchbar");
            toggleElement.innerHTML = "Show Search";
        }
        
        this.setState({ toggleSearchbar: !toggleSearchbar }, () => {
            this.computeFooterMargin();
        });
    }

    handleInputClick = e => {
        const { showCalendar } = this.state;
        let header = document.querySelector('header');

        if (header) {
            if (!showCalendar) {
                header.style.zIndex = 10;
            }
            else {
                header.style.zIndex = 51;
            }
        }
        this.setState({ showCalendar: !showCalendar });
    }

    onDatesChange = async ({ startDate, endDate }) => {
        const { listingId } = this.props;
        let { data, showCalendar, minimumNights, focusedInput, monthShown } = this.state;

        if (startDate) {
            data.checkin = startDate.format("MM/DD/YYYY");
            if (listingId) {
                const selectedDateStatus = monthShown.tempRawDateSched.find(x => dayjs(x.date).isSame(startDate, 'date'));
                if (selectedDateStatus) {
                    minimumNights = selectedDateStatus.minNights;
                }
            }
        }
        if (endDate) {
            data.checkout = endDate.format("MM/DD/YYYY");
        }

        if (focusedInput === 'endDate') {
            showCalendar = false;
        }

        if (listingId && startDate && endDate) {
            this.setState({ data, showCalendar, startDate, endDate }, async () => {
                await this.getListingPrice();
            });
        }
        else {
            this.setState({ data, showCalendar, startDate, endDate, minimumNights });
        }
    }

    isDayBlocked(day) {
        const { listingId } = this.props;

        if (listingId) {
            const { data, monthShown, focusedInput, isListed } = this.state;
            const { maxNights } = this.props;
            const isInvalid = monthShown.tempInvalidDates.some(i => day.isSame(i.date, 'day'));

            if (!isListed) {
                return true;
            }

            if (isInvalid) {
                return isInvalid;
            }

            if (data.checkin && focusedInput === 'endDate') {
                // apply min and max nights
                const startDate = new Date(data.checkin);
                const maxPossibleEndDate = new Date(data.checkin);
                maxPossibleEndDate.setDate(maxPossibleEndDate.getDate() + maxNights);

                const selectedDateStatus = monthShown.tempRawDateSched.find(x => dayjs(x.date).isSame(startDate, 'date'));
                if (selectedDateStatus) {
                    const maxMinimumNightEndDate = new Date(data.checkin);
                    //console.log("selectedDateStatus: ", selectedDateStatus);
                    maxMinimumNightEndDate.setDate(maxMinimumNightEndDate.getDate() + selectedDateStatus.minNights);
    
                    if (day.isBetween(startDate, maxMinimumNightEndDate)) {
                        return false;
                    }
                    if (day.isSame(maxMinimumNightEndDate, 'date')) {
                        return false;
                    }
                }
                // console.log("isDayBlocked",startDate,day,maxPossibleEndDate,!day.isBetween(startDate, maxPossibleEndDate))
                return !day.isBetween(startDate, maxPossibleEndDate);
            }
            
            return false;
        }
    }

    isOutsideRange(day) {
        // const { listingId } = this.props;   
        // const { isListed } = this.state;
        // if (listingId) {
        //     if (!isListed) {
        //         return true;
        //     }
        // }
        
        return !isInclusivelyBeforeDay(moment(), day);
    }

    handleClearDates() {
        //clear checkin and checkout states
        var data = this.state.data;

        this.setState({data : {...data, checkin: '', checkout: ''},startDate: null, endDate: null});
    }

    render() {
        const { listingId, price } = this.props;
        let { isLoadingPrice, showCalendar, selectedGuests, guestOptions,
            screenSize, initialDatesAvailabilityLoaded, startDate, endDate, minimumNights, 
            calendarOrientation, focusedInput, monthShown, isListed } = this.state;
        const { checkin, checkout, listingInfo } = this.state.data;
        let buttonText = '';
        let inquireButtonText = 'Inquire';
        const theme = this.context;

        // const blockedDates = monthShown.tempInvalidDates.length > 0 ? this.isDayBlocked : noop;
        // const isOutsideRange = monthShown.tempInvalidDates.length > 0 ? this.isOutsideRange : noop;
        let blockedDates = monthShown.tempInvalidDates.length > 0 ? this.isDayBlocked : noop;
        let isOutsideRange = monthShown.tempInvalidDates.length > 0 ? this.isOutsideRange : noop;

        if (listingId && !isListed) {
            blockedDates = this.isDayBlocked;
            isOutsideRange = this.isOutsideRange;
        }

        if(listingId) {
            buttonText = 'Book';
        }
        else {
            buttonText = 'Search';
        }

        if (startDate !== null) {
            startDate = moment(startDate.toDate());
        }
        if (endDate !== null) {
            endDate = moment(endDate.toDate());
        }
        
        return (
            <React.Fragment>
                {
                    // showCalendar && <div className="js-calendar-overlay" onClick={() => this.setState({ showCalendar: false })}></div>
                }
                <div className={`sticky sticky-bottom searchbar layout-row ${listingId ? `is-listing` : ``}`}>
                    <div className="searchbar-price-info" onClick={() => this.setState({ showCalendar: false })}>
                        <div className="searchbar-toggle" ref={this.toggleButtonRef} onClick={this.onSearchbarToggle}>Hide Search</div>
                        {/* Contains the price */}
                        <div className="layout-row" style={{ height: '100%' }}>
                            {
                                isLoadingPrice ?
                                <div className="layout-row layout--centered searchbar-loader">
                                    <img src={loader} alt="Loading..." />
                                </div> 
                                :
                                <ul className="layout-row layout--centered flex">
                                    {
                                        listingId ? 
                                        <React.Fragment>
                                            <li className={`${screenSize.width <= 576 && checkout ? 'has-border-bottom' : ''}`}>
                                                <div className="price-avg-per-night">{toDanishCurrency(listingInfo.avgPerNight === 0 ? price.basePrice : listingInfo.avgPerNight)}</div>
                                                <div className="price-label">avg. per night</div>
                                            </li>
                                            {
                                                checkout &&
                                                <React.Fragment>
                                                    <li>
                                                        <div className="price-label">{`${listingInfo.totalNights} night${listingInfo.totalNights > 1 ? 's': ''}:`}</div>
                                                        <div className="price-amount">{toDanishCurrency(listingInfo.totalPrice)}</div>
                                                    </li>
                                                    <li>
                                                        <div className="price-label">Cleaning fee:</div>
                                                        <div className="price-amount">{toDanishCurrency(price.cleaningFee)}</div>
                                                    </li>
                                                    <li>
                                                        <div className="price-label">Total:</div>
                                                        <div className="price-amount">{toDanishCurrency(listingInfo.totalPrice + price.cleaningFee)}</div>
                                                    </li>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>
                                        :
                                        screenSize.width < 576 ? "" :
                                        <li className="color-black default-label">
                                            Search Dinesen Collection Luxury Condos
                                        </li>
                                    } 
                                </ul>
                            }
                        </div>
                    </div>
                    <div className="searchbar-schedule">
                        <div className={`searchbar-calendar-range ${showCalendar ? 'calendar-range--active' : ''}`}>
                            {
                                ( !initialDatesAvailabilityLoaded && listingId) ? 
                                <div className="js-calendar-loading">
                                    <div>
                                        <img src={pageloader} alt="Loading..." />
                                        Loading available dates...
                                    </div>
                                </div> :
                                <DayPickerRangeController 
                                    startDate={startDate}
                                    endDate={endDate}
                                    onDatesChange={this.onDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput || "startDate" })}
                                    numberOfMonths={2}
                                    hideKeyboardShortcutsPanel={true}
                                    minimumNights={minimumNights}
                                    isDayBlocked={blockedDates}
                                    isOutsideRange={isOutsideRange}
                                    orientation={calendarOrientation}
                                    onPrevMonthClick={this.onPreviousMonthClick}
                                    onNextMonthClick={this.onNextMonthClick}
                                    onOutsideClick={() => this.setState({ showCalendar: false })}
                                />
                            }
                            
                        </div>
                        <div className="layout-row" style={{height: '100%' }}>
                            <ul className="layout-row flex layout--centered searchbar-schedule--panel">
                                <li key="searchbar_schedule_sched" className="text-right">
                                    <React.Fragment>
                                        <div className={`searchbar-data-range__inputs ${(checkin && checkout) ? 'has-dates':''}`}>
                                            <div className="searchbar-field">
                                                <input type="text" id="checkin" className={`${checkin ? 'has-value': ''}`} readOnly={true} value={checkin} onClick={this.handleInputClick} />
                                                <label className="label-name ic-calendar">
                                                    <span className="content-name">Check-in</span>
                                                </label>
                                            </div>
                                            <div className="searchbar-field">
                                                <input type="text" id="checkout" className={`${checkout ? 'has-value': ''}`} readOnly={true} value={checkout} onClick={this.handleInputClick} />
                                                <label className="label-name ic-calendar">
                                                    <span className="content-name ">Check-out</span>
                                                </label>
                                            </div>
                                            {
                                                checkin && checkout && 
                                                <div className="searchbar-field">
                                                    <span className='clear-dates' onClick={this.handleClearDates}>
                                                        <svg className="_13vll7g" focusable="false" viewBox="0 0 12 12"><path fill-rule="evenodd" d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"></path></svg>
                                                    </span>
                                                </div>
                                            }
                                            
                                        </div>
                                    </React.Fragment>
                                </li>
                                <li key="searchbar_schedule_guests" className="text-left">
                                    <div className="searchbar-guests-count">
                                        <Select value={selectedGuests} onChange={this.onGuestCountChange} 
                                            isSearchable={false}
                                            placeholder="Guest(s)"
                                            options={guestOptions}
                                            styles={{
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    // top: -265
                                                    transform: 'translateY(-125%)'
                                                }),
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    height: 54,
                                                    marginBottom: 3,
                                                    borderColor: '#C5C5C5'
                                                }),
                                                indicatorSeparator: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: 'transparent'
                                                }),
                                                dropdownIndicator: (provided, state) => ({
                                                    ...provided,
                                                    color: 'rgba(23, 20, 17, 1)'
                                                })
                                            }} 
                                        />
                                    </div>
                                </li>
                                <li key="searchbar_schedule_action_button" className="text-right">
                                    { !listingId && <button className={`btn btn-primary ${theme.buttonPrimary}`} type="button" onClick={this.onButtonClick}>{buttonText}</button> }
                                    <div className="button-group">
                                        { listingId && <button disabled={isLoadingPrice || !isListed} className={`btn btn-primary ${theme.buttonPrimary}`} type="button" onClick={this.onButtonClick}>{buttonText}</button> }
                                        { listingId && <button disabled={isLoadingPrice || !isListed} className={`btn btn-secondary ${theme.buttonPrimary}`} type="button" onClick={this.onInquire}>Send inquiry</button> }
                                        {/* {listingId && <ButtonDropdown links={[{callback:this.onInquire,text:"Send Inquiry"}]} />} */}
                                    </div>
                                    {/* { listingId && <button disabled={isLoadingPrice || !isListed} className={`btn btn-primary ${theme.buttonPrimary}`} type="button" onClick={this.onInquire}>{inquireButtonText}</button> } */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ScrollTop />
            </React.Fragment>
        )
    }
}

Searchbar.contextType = ThemeContext;

Searchbar.propTypes = {
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    numberOfGuests: PropTypes.number,
    maxNumberOfGuests: PropTypes.number,
    price: PropTypes.object,
    listingId: PropTypes.string
}

export default Searchbar;
