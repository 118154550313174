import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import { Link as ReactScrollLink } from 'react-scroll';

const Link = ({ path, to, className, activeClassName, onClick, children }) => {
    let linkProps = {
        className,
        activeClassName
    };

    if (onClick) {
        linkProps.onClick = () => {
            onClick();
        }
    }

    let tempPath = path;
    let tempTo = to;
    
    if (typeof window !== 'undefined') {
        if (tempPath.includes('#')) {
            tempPath = tempPath.substring(0, path.indexOf("#"));
        }
    
        if (tempTo.includes('#')) {
            tempTo = tempTo.substring(0, to.indexOf("#"));
        }
    }
    
    let defaultTo = to;
    let defaultToWithoutHash = to.substring(0, to.indexOf("#"));

    if (to.includes('#') && defaultToWithoutHash === path) {
        // linkProps.href = to;
        linkProps.to = to.replace("#", '');
        linkProps.to = linkProps.to.replace("/", '');

        return (
            // <a itemProp="url" {...linkProps}>
            //     {children}
            // </a>
            <ReactScrollLink itemProp="url" activeClass='active-menu' {...linkProps} spy={true} hashSpy={true} smooth={true} duration={500} onSetActive={() => {
                window.history.pushState(null,null,defaultTo);
            }}>
                {children}
            </ReactScrollLink>
        )
    }

    linkProps.to = to;

    return <GatsbyLink itemProp="url" {...linkProps}>{children}</GatsbyLink>;
}

export default Link;