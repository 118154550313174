import { useState, useEffect } from 'react';

export const useResize = () => {
    const [ size, setSize ] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSize({
                width: document.body.getBoundingClientRect().width,
                height: document.body.getBoundingClientRect().height
            });
        });

        setSize({
            width: document.body.getBoundingClientRect().width,
            height: document.body.getBoundingClientRect().height
        });

        return function release() {
            window.removeEventListener('resize', () => {
                setSize({
                    width: document.body.getBoundingClientRect().width,
                    height: document.body.getBoundingClientRect().height
                });
            });
        }
    }, []);

    return size;
}

export const useScroll = () => {
    const [ scroll, setScroll ] = useState({
        X: 0,
        Y: 0
    });

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll({
                X: window.scrollX || window.pageXOffset,
                Y: window.scrollY || window.pageYOffset
            });
        });
        
        setScroll({
            X: window.scrollX,
            Y: window.scrollY
        });

        return function release() {
            window.removeEventListener('scroll', () => {
                setScroll({
                    X: window.scrollX,
                    Y: window.scrollY
                });
            });
        }
    }, []);

    return scroll;
}