import React from 'react';
import Img from 'gatsby-image';

import facebookLogo from '../images/ico-fb.svg';
import instagramLogo from '../images/ico-ig.svg';

export default function footer({ footer }) {
    return (
        <footer className="footer">
            <div style={{
                width: 100,
                height: 100,
                margin: `0 auto`
            }}>
                <Img className="img" fixed={footer?.footerImageQuery?.thumbnail} />
            </div>
            <div className="footer-contact-details" style={{
                marginTop: `1.5rem`
            }}>
                <div className="footer-contact--address">{footer.companyAddress}</div>
                <div className="footer-contact--infos">
                    <span><a href={`mailto:${footer.companyEmail}`}>{footer.companyEmail}</a></span>
                    <span><a href={`tel:${footer.companyContactNumber}`}>Tel: {footer.companyContactNumber}</a></span>
                </div>
            </div>
            <div className="policies">
                <ul className="layout-row layout--centered">
                    <li className="flex text-right"><a href="/cancellation-policy/" target="_blank">Cancellation Policy</a></li>
                    <li className="flex text-left"><a href="/privacy-policy/" target="_blank">Privacy Policy</a></li>
                </ul>
            </div>
            {/* <div className="text-center socials">
                <a rel="noopener noreferrer" href={footer.facebookUrl} target="_blank">
                    <img src={facebookLogo} alt="" />
                </a>
                <a rel="noopener noreferrer" href={footer.instagramUrl} target="_blank">
                    <img src={instagramLogo} alt="" />
                </a>
            </div> */}
            <Img fluid={footer?.panoramicImageQuery?.fluid} style={{opacity: 0.1}} />
        </footer>
    )
}

