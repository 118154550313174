import React, { useState } from "react";
import PropTypes from "prop-types";
// import { ThemeContext } from '../context/ThemeProvider';
import { useScroll, useResize } from './hooks/window';
import Link from './Link';
import Img from 'gatsby-image';

import AppTextBlock from './SvgImages/AppTextBlock';

function Header ({ headerMenu, showHeaderMark, defaultHeaderTextToBlack, isStickyHeader, scrollYIndex, path, delayHeaderRender, headerImageQuery }) {
    // const { activeMenu } = useContext(ThemeContext);
    const [showMenu, setShowMenu] = useState(false);
    const scroll = useScroll();
    const size = useResize();

    let textColor = 'white';
    let scrollY = scrollYIndex || 100;
    let headerClass = '';
    let headerStyle = {};
    // let logoMarkStyle = '';
    let mainLogoClassName = '';

    if (isStickyHeader) {
        if (scroll.Y > scrollY) {
            textColor = 'black';
            headerClass = 'sticky sticky-top is-header-sticky';
        }
        else {
            // For /listings page
            if (defaultHeaderTextToBlack) {
                textColor = 'black';
            }
        }
    }
    else {
        if (scroll.Y > scrollY) {
            textColor = 'black';
            headerClass = 'sticky sticky-top is-header-sticky';
            
            if (path === '/') {
                mainLogoClassName = '';
            }
        }
        else {
            if (path === '/') {
                mainLogoClassName = 'is-hidden';
            }
            
            // For /listings page
            if (defaultHeaderTextToBlack) {
                textColor = 'black';
            }
        }
    }

    let navbarMenuStyle = {};
    let navbarLogoStyle = {};

    if (!showHeaderMark) {
        if (size.width > 800) {    
            navbarLogoStyle = {
                flex: `1 0 210px`
            };

            navbarMenuStyle = {
                flex: `1 0 70%`
            };
        }
        else {  
            navbarLogoStyle = {
                flex: `1 0 45%`
            };

            navbarMenuStyle = {
                flex: `1`
            };
        }
    }

    // if (delayHeaderRender) {
    //     headerStyle = {
    //         opacity: 0
    //     }
        
    //     setTimeout(() => {
    //         let header = document.getElementsByTagName("header")[0];
    //         if (header) {
    //             header.style.opacity = 1;
    //         }
    //     }, 3000);
    // }

    return (
        <React.Fragment>
            <header className={`layout-row layout--centered ${showMenu ? 'bm-menu-active' : ''} ${headerClass}`} style={headerStyle}>
                <div className={`navbar-logo ${textColor} ${showMenu ? 'is-hidden' : ''} ${mainLogoClassName}`} style={navbarLogoStyle}>
                    <a href="/">
                        <AppTextBlock />
                    </a>
                </div>
                {
                    size.width > 0 &&
                    <div className="navbar" style={navbarMenuStyle}>
                        {
                            size.width > 800 && 
                            <ul className={`navbar-menu`} itemScope="itemScope" itemType="https://schema.org/SiteNavigationElement" role="navigation">
                            {
                                headerMenu.map((item, index) => {
                                    let settings = {};

                                    if (!item.url.url.includes('#')) {
                                        settings.activeClassName= 'active-menu';
                                    }

                                    
                                    if (!item.url.url.startsWith('https://')) {
                                        return (
                                            <li key={`header__${index}__menu`}>
                                                <Link to={item.url.url} itemProp="url" className={textColor} {...settings} path={path}>
                                                    <span title={item.title}>{item.title}</span>
                                                </Link>
                                            </li>
                                        )
                                    }
                                    else {
                                        return (
                                            <li key={`header__${index}__menu`}>
                                                <a href={item.url.url} itemProp="url" target={item.url.target} className={textColor}  >
                                                    <span title={item.title}>{item.title}</span>
                                                </a>
                                            </li>
                                        )
                                    }
                                })
                            }
                            </ul>
                        }
                        {
                            size.width <= 800 && 
                            <div className={`burger-menu ${showMenu ? 'is-active' : ''}`} onClick={(e) => {
                                e.stopPropagation();
                                
                                if (showMenu) {
                                    document.body.style.overflowY = '';
                                }
                                else {
                                    document.body.style.overflowY = 'hidden';
                                }

                                setShowMenu(!showMenu);
                            }}>
                                <div className={`burger-menu-inner ${showMenu ? 'black' : textColor}`}></div>
                            </div> 
                        }
                    </div>
                }
            </header>
            { 
                size.width <= 786 && 
                <div className={`slider-menu ${showMenu ? 'is-active' : ''}`}>
                    <div className="slider-menu-inner">
                        <div className="slider-menu__logomark-img">
                            <Img className="img" fixed={headerImageQuery?.thumbnail} alt="Logo" />
                        </div>
                        <div className="slider-menu__mob-menu">
                            <ul className={`navbar-menu`} itemScope="itemScope" itemType="https://schema.org/SiteNavigationElement" role="navigation">
                                {
                                    headerMenu.map((item, index) => {

                                        if(!item.url.url.startsWith("https")) {
                                            return (
                                                <li key={`header__${index}__menu`}>
                                                    <Link to={item.url.url} itemProp="url" className={textColor} activeClassName={`${item.url.url.includes('#') ? '' : 'active-menu'}`} path={path} onClick={() => {
                                                        setShowMenu(false);
                                                        document.body.style.overflowY = '';
                                                    }}>
                                                        <span>{item.title}</span>
                                                    </Link>
                                                </li>
                                            )
                                        }
                                        else {
                                            return (
                                                <li key={`header__${index}__menu`} >
                                                    <a href={item.url.url} itemProp="url" target={item.url.target}  className={textColor} >
                                                        <span>{item.title}</span>
                                                    </a>
                                                </li>
                                            )
                                        }

                                        
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

Header.propTypes = {
  headerMenu: PropTypes.object,
}

export default Header
