import React, { Component } from "react";
import PropTypes from "prop-types";
import SEO from './seo';

import Header from "./header";
import Footer from './footer';
import Searchbar from './Searchbar';

class Layout extends Component {
  render() {
    const { header, favicon, footer, seo, searchBar, defaultHeaderTextToBlack, showHeaderMark, isStickyHeader, scrollYIndex, hideFooter, path, delayHeaderRender } = this.props;

    return (
      <React.Fragment>
        <SEO favicon={favicon} {...seo} bgBanner={header.bgBanner || null} />
        <Header {...header} defaultHeaderTextToBlack={defaultHeaderTextToBlack} showHeaderMark={showHeaderMark} 
          isStickyHeader={isStickyHeader} scrollYIndex={scrollYIndex} path={path} delayHeaderRender={delayHeaderRender} />
        <main>
          {this.props.children}
        </main>
        { !hideFooter && <Footer footer={footer} /> }
        <Searchbar {...searchBar} />
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.object,
  favicon: PropTypes.object,
  footer: PropTypes.object
}

export default Layout
