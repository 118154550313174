import React, { Component } from 'react';
import PropTypes from "prop-types";

class ScrollTop extends Component {

    constructor() {
        super();
        this.state = {
            showScroll: false,
        }
    }
    
    componentDidMount() {
        if(typeof window !== 'undefined') {
            window.addEventListener('scroll', this.checkScrollTop)
        }
    }

    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    checkScrollTop = () => {
        if (window.pageYOffset > (window.innerHeight / 3)) {
            this.setState({ showScroll: true });
        } else {
            this.setState({ showScroll: false });
        }
    }

    render() {

        const { showScroll } = this.state;

        return(
            <div id="scrollTop" onClick={this.scrollTop} style={{
                display: showScroll ? 'block':'none',
            }}></div>
        )

    }
}


ScrollTop.propTypes = {
    bottom: PropTypes.number,
}


export default ScrollTop;